import type { organiseConfiguration } from './organise'

export const ClientConfigurationKeys = [
  'IS_DEBUGGING_ALLOWED',
  'IS_MOCKING_INTERACTIVE',
  'APOLLO_ARE_DEV_TOOLS_CONNECTED',
  'RETRIES_ATTEMPTS_MAX',
  'RETRIES_DELAY_INITIAL',
  'RETRIES_DELAY_MAX',
  'RETRIES_DELAY_JITTER',
  'JUPITER_INTERACTIVE_CONTENT_RELEASE_SHA',
  'INTERACTIVE_CONTENT_RELEASE_SHA',
  'INTERACTIVE_CONTENT_ASSET_HOSTS_ASSETS',
  'INTERACTIVE_CONTENT_ASSET_HOSTS_BOOKS',
  'INTERACTIVE_CONTENT_ASSET_HOSTS_CODE',
  'INTERACTIVE_CONTENT_ASSET_HOSTS_VIDEO',
  'MATHSEEDS_URL',
  'GRAPHQL_URL',
  'LOGIN_URL',
  'LOGOUT_URL',
  'DATADOG_RUM_APPLICATION_ID',
  'DATADOG_RUM_CLIENT_TOKEN',
  'DATADOG_RUM_PROXY_URL',
  'DATADOG_RUM_SESSION_SAMPLE_RATE',
  'ROLLBAR_ACCESS_TOKEN',
  'ENVIRONMENT',
  'IS_ROLLBAR_ENABLED',
  'IS_BROWSER_BASED_CONFIG_ALLOWED',
] as const

export type ClientConfigurationKey = (typeof ClientConfigurationKeys)[number]
export type Configuration = Record<ClientConfigurationKey, string>
export type StructuredConfiguration = ReturnType<typeof organiseConfiguration>
export type ConfigurationConsumer = { configuration: StructuredConfiguration }
