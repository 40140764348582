import { DefaultConfiguration, forSource, withBase } from './sources'
import type { Configuration } from './types'

export default function resolveConfiguration(namespace = 'MSP_CLIENT_') {
  const runtimeConfiguration = forSource(process.env).readConfiguration(namespace)
  const flatConfiguration = withBase(DefaultConfiguration).merge(runtimeConfiguration)
  return organiseConfiguration(flatConfiguration)
}

export const organiseConfiguration = (configuration: Configuration) => ({
  mathseedsUrl: configuration.MATHSEEDS_URL,
  debugging: {
    isAllowed: configuration.IS_DEBUGGING_ALLOWED === 'true',
    isMockingInteractiveByDefault: configuration.IS_MOCKING_INTERACTIVE === 'true',
  },
  apollo: {
    areDevToolsConnected: configuration.APOLLO_ARE_DEV_TOOLS_CONNECTED === 'true',
  },
  interactiveContent: {
    activities: {
      namespace: 'mathseeds-prime',
      releaseSha: process.env['JUPITER']
        ? configuration.JUPITER_INTERACTIVE_CONTENT_RELEASE_SHA
        : configuration.INTERACTIVE_CONTENT_RELEASE_SHA,
    },
    assetHosts: {
      assets: process.env['CDN_URL'] || configuration.INTERACTIVE_CONTENT_ASSET_HOSTS_ASSETS,
      books: configuration.INTERACTIVE_CONTENT_ASSET_HOSTS_BOOKS,
      code: process.env['CDN_URL'] || configuration.INTERACTIVE_CONTENT_ASSET_HOSTS_CODE,
      video: configuration.INTERACTIVE_CONTENT_ASSET_HOSTS_VIDEO,
    },
    isEsmPreferred: true,
  },
  retries: {
    attempts: {
      max: Number(configuration.RETRIES_ATTEMPTS_MAX),
    },
    delay: {
      initial: Number(configuration.RETRIES_DELAY_INITIAL),
      max: Number(configuration.RETRIES_DELAY_MAX),
      jitter: configuration.RETRIES_DELAY_JITTER === 'true',
    },
  },
  graphQlUrl: configuration.GRAPHQL_URL,
  loginUrl: configuration.LOGIN_URL,
  logoutUrl: configuration.LOGOUT_URL,
  datadog: {
    environment: process.env['DD_ENV'] || configuration.ENVIRONMENT,
    version: process.env.gitSha || '',
    rum: {
      applicationId: configuration.DATADOG_RUM_APPLICATION_ID,
      clientToken: configuration.DATADOG_RUM_CLIENT_TOKEN,
      proxyUrl: configuration.DATADOG_RUM_PROXY_URL,
      sessionSampleRate: Number(configuration.DATADOG_RUM_SESSION_SAMPLE_RATE),
    },
  },
  environment: configuration.ENVIRONMENT,
  rollbar: {
    accessToken: configuration.ROLLBAR_ACCESS_TOKEN,
    isEnabled: configuration.IS_ROLLBAR_ENABLED === 'true',
  },
  isBrowserBasedConfigAllowed: configuration.IS_BROWSER_BASED_CONFIG_ALLOWED === 'true',
})
